<template>
  <data-chip :text="getReasonTitle(reason.id)" :small="small"> </data-chip>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "leave-reason-chip",
  components: {
    DataChip,
  },
  props: {
    reason: {
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reasons: [
        { id: "0", title: "Keine Angabe", description: "" },
        {
          id: "1",
          title: "Verpflichtungen/Verbindlichkeiten/Termine",
          description: "Bspw. berufliche, private, familiäre Verpflichtungen",
        },
        { id: "2", title: "Nicht in Stadt", description: "Nicht erreichbar" },
        {
          id: "3",
          title: "Keine Diensttauglichkeit",
          description: "Bspw. aufgrund von Krankheit, Verletzung, Ablauf G26.3",
        },
        {
          id: "4",
          title: "Eingeschränkte Diensttauglichkeit",
          description: "Bspw. duch Ablauf von Prüfdaten",
        },
        {
          id: "8",
          title: "Beurlaubung",
          description: "Ruhen von Rechten und Pflichten",
        },
        { id: "9", title: "Sonstiges", description: "" },
      ],
    };
  },
  methods: {
    getReasonTitle(id) {
      const reason = this.reasons.find((reason) => reason.id === id);
      return reason ? reason.title : "Unbekannt";
    },
  },
};
</script>
