<template>
  <div>
    <v-progress-linear
      :striped="striped"
      :value="progress"
      :height="height"
      rounded
      :color="color"
      ><template v-slot:default="{ value }">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template></v-progress-linear
    >
  </div>
</template>

<script>
export default {
  name: "date-progress-bar",
  props: {
    start: {
      type: Object, // Firestore Timestamp
      required: true,
    },
    end: {
      type: Object, // Firestore Timestamp
      required: true,
    },
    striped: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "blue lighten-4",
    },
    height: {
      type: String,
      default: "30",
    },
  },
  data() {
    return {
      now: new Date(),
    };
  },
  computed: {
    progress() {
      const start = this.start.toDate();
      const end = this.end.toDate();
      if (this.now < start) return 0;
      if (this.now > end) return 100;
      return ((this.now - start) / (end - start)) * 100;
    },
  },
  created() {
    const start = this.start.toDate();
    const end = this.end.toDate();
    const totalDuration = end - start;
    const onePercentDuration = totalDuration / 100;

    this.timer = setInterval(() => {
      this.now = new Date();
      if (this.progress >= 100) {
        clearInterval(this.timer);
      }
    }, onePercentDuration);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
