<template>
  <div>
    <section>
      <TippCard
        hintID="Verfügbarkeit_Archiv_Abfragen_1_V1"
        text="Im Archiv können vergangene Abfragen mit allen Details und Rückmeldungen eingesehen werden. Abfragen können dabei auf einen bestimmten Zeitraum begrenzt werden."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-archive"
                title="Vergangene Abwesenheitsmeldungen"
              >
              </Subheader>
              <div class="mt-7">
                <v-card :loading="loading">
                  <v-card-text>
                    <v-container class="mb-n10">
                      <v-form
                        @submit.prevent="startQuery"
                        v-model="formIsValid"
                        ref="form"
                      >
                        <v-row>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="query.range.start"
                              type="date"
                              label="Startdatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :disabled="loading"
                              clearable
                              prepend-inner-icon="mdi-calendar-start"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="query.range.end"
                              type="date"
                              label="Enddatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :rules="[rules.required]"
                              required
                              :disabled="loading"
                              prepend-inner-icon="mdi-calendar-start"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="success"
                      :disabled="loading"
                      @click="startQuery()"
                      class="mr-5"
                      >Abfrage starten</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </div>
              <div class="mt-7">
                <leaves-data-table :leaves="archiveMatched"></leaves-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import LeavesDataTable from "@/components/availability/leaves/LeavesDataTable.vue";
import { AVAILABILITY } from "@/store/modules.js";
import { GET_ARCHIVED_LEAVES } from "@/store/action-types.js";

export default {
  name: "availability-archive-tab-leaves",
  components: {
    Subheader,
    TippCard,
    SupportTools,
    LeavesDataTable,
  },
  data() {
    return {
      search: "",
      sortDesc: true,
      sortBy: "title",
      groupBy: "groupBy",
      groupDesc: true,

      // Table Display Settings
      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },

      query: {
        range: {
          start: null,
          end: null,
        },
        status: ["postponed", "canceled", "finished"],
        types: [],
        groups: [],
      },
      formIsValid: false,
      loading: true,

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  computed: {
    userGroups() {
      const userGroups = this.$store.state.admin.userGroups.map((obj) => ({
        title: obj.title,
        id: obj.meta.id,
      }));

      userGroups.unshift({
        title: "Alle",
        id: "all",
      });
      return userGroups;
    },
    archivedLeaves() {
      return this.$store.state.availability.archivedLeaves;
    },
    archiveMatched() {
      return this.archivedLeaves.map((leave) => ({
        ...leave,
        groupBy: this.groupByDateText(leave.duration.start.timestamp.toDate()),
        status: this.getLeaveStatus(
          leave.duration.start.timestamp.toDate(),
          leave.duration.end.timestamp.toDate()
        ),
      }));
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "archiveMatched", data: this.archiveMatched },
        { title: "query", data: this.query },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.query.range.end = new Date().toISOString().substr(0, 10);

      this.loading = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    startQuery() {
      this.validate();
      if (this.formIsValid) {
        if (this.query.range.start == "") {
          this.query.range.start = null;
        }
        this.$store.dispatch(`${AVAILABILITY}${GET_ARCHIVED_LEAVES}`, {
          organizationId: this.$route.params.organizationId,
          query: {
            range: {
              start: this.query.range.start,
              end: this.query.range.end,
            },
          },
        });
      } else {
        alert("Fehler: Es ist ein Enddatum für die Abfrage erforderlich.");
      }
    },
    getLeaveStatus(start, end) {
      const now = new Date().getTime();

      const startTime = new Date(start).getTime();
      const endTime = new Date(end).getTime();

      if (startTime > now) {
        return "upcoming";
      } else if (startTime <= now && endTime >= now) {
        // Wenn Startzeit jetzt oder in Vergangenheit und Endzeit noch in der Zukunft liegt, dann "active"
        return "active";
      } else if (endTime < now) {
        // Wenn Startzeit und Endzeit in der Vergangenheit liegen, dann "finished"
        return "finished";
      } else {
        return "error";
      }
    },
    groupByDateText(date) {
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const monthLong = date.toLocaleString("de-DE", { month: "long" });
      return year + "-" + month + "_" + monthLong + " " + year;
    },
  },
};
</script>

<style></style>
